import Axios from "axios";
import React, { useState } from "react";
require('dotenv').config();
const PORT = process.env.PORT || 5000;

function ContactForm() {

    const [formData, setFormData] = useState({
        lastname: "",
        firstname: "",
        brand: "",
        email: "",
        message: ""
    });

    const [status, setStatus] = useState("Envoyer");

    const handleSubmit = () => {
        setStatus("Envoi...");
        Axios.post(`http://localhost:${PORT}/send`, {
            lastname: formData.lastname,
            firstname: formData.firstname,
            brand: formData.brand,
            email: formData.email,
            message: formData.message
        });
        setStatus("Envoyé");
    };
    
    return (
        <form className="contact-form" onSubmit={handleSubmit}>
            <h1 className="contact-form--title">Nous contacter</h1>
            <label className="contact-form--label" htmlFor="name">Nom* :</label>
            <input className="contact-form--input" type="text" onChange={(e) => setFormData({...formData, lastname:e.target.value})} value={formData.lastname} id="lastname" required />
            <label className="contact-form--label" htmlFor="name">Prénom* :</label>
            <input className="contact-form--input" type="text" onChange={(e) => setFormData({...formData, firstname:e.target.value})} value={formData.firstname} id="firstname" required />
            <label className="contact-form--label" htmlFor="name">Société :</label>
            <input className="contact-form--input" type="text" onChange={(e) => setFormData({...formData, brand:e.target.value})} value={formData.brand} id="brand" />
            <label className="contact-form--label" htmlFor="email">Email* :</label>
            <input className="contact-form--input" type="email" onChange={(e) => setFormData({...formData, email:e.target.value})} value={formData.email} id="email" required />
            <label className="contact-form--label" htmlFor="message">Message* :</label>
            <textarea className="contact-form--textarea" id="message" onChange={(e) => setFormData({...formData, message:e.target.value})} value={formData.message} required />
            <button className="contact-form--button" type="submit">{status}</button>
        </form>
    );
}

export default ContactForm;