import './scss/Team.scss'
import img_bertrand from './../Images/team/bertrand.jpg';
import img_alexandre from './../Images/team/alexandre.jpg';
import img_clement from './../Images/team/clement.jpg';
import img_nisrine from './../Images/team/nisrine.jpg';
import img_sarah from './../Images/team/sarah.jpg';
import img_celine from './../Images/team/celine.jpg';
import img_raoul from './../Images/team/raoul.jpg';
import img_gnagne from './../Images/team/gnagne.jpg';
import img_nicolas from './../Images/team/nicolas.jpg';
import img_hiba from './../Images/team/hiba.jpg';
import img_emilie from './../Images/team/emilie.jpg';
import img_xavier from './../Images/team/xavier.jpg';
import img_jonathan from './../Images/team/jonathan.jpg';
import img_thomas from './../Images/team/thomas.jpg';
import img_alaadine from './../Images/team/alaadine.jpg';
import img_lucas from './../Images/team/lucas.jpg';
import img_lucie from './../Images/team/lucie.jpg';
import img_baptiste from './../Images/team/baptiste.jpg';
import img_hajar from './../Images/team/hajar.jpg';


function Team() {

    const members = [
        {
            'name': "Bertrand TOUPANCE",
            'image': img_bertrand,
            'job': "Président"
        },
        {
            'name': "Alexandre MACÉ",
            'image': img_alexandre,
            'job': "Vice-président"
        },
        {
            'name': "Clément RAULT",
            'image': img_clement,
            'job': "Secrétaire Général"
        },
        {
            'name': "Nisrine ARHNOUCH",
            'image': img_nisrine,
            'job': "Trésorière"
        },
        {
            'name': "Sarah ROUBERTOU",
            'image': img_sarah,
            'job': "Responsable Qualité"
        },
        {
            'name': "Céline RISSE",
            'image': img_celine,
            'job': "Vice-trésorière"
        },
        {
            'name': "Raoul DAMIEN",
            'image': img_raoul,
            'job': "Comptable"
        },
        {
            'name': "Gnagne DIOP",
            'image': img_gnagne,
            'job': "Responsable RH/RSE"
        },
        {
            'name': "Nicolas LIM",
            'image': img_nicolas,
            'job': "Responsable Performance"
        },
        {
            'name': "Hiba HANDOUKI",
            'image': img_hiba,
            'job': "Suiveuse d'études"
        },
        {
            'name': "Émilie CUVELLIER",
            'image': img_emilie,
            'job': "Responsable Développement Commerciale"
        },
        {
            'name': "Xavier VELER",
            'image': img_xavier,
            'job': "Responsable Évènement"
        },
        {
            'name': "Jonathan DE BRUSSE",
            'image': img_jonathan,
            'job': "Responsable Évènement"
        },
        {
            'name': "Thomas BONNET",
            'image': img_thomas,
            'job': "Responsable Communication"
        },
        {
            'name': "Alaadine ELJEBARI",
            'image': img_alaadine,
            'job': "Responsable Communication"
        },
        {
            'name': "Lucas MARCHALAND",
            'image': img_lucas,
            'job': "Responsable Prospection"
        },
        {
            'name': "Lucie COUCHEVELLOU",
            'image': img_lucie,
            'job': "Chargée de mission"
        },
        {
            'name': "Baptiste LECHEVALLIER",
            'image': img_baptiste,
            'job': "Prospecteur"
        },
        {
            'name': "Hajar SBAI",
            'image': img_hajar,
            'job': "Prospectrice"
        }
    ];

    const displayMember = (member) => {
        return(
            <div className="team-members--member">
                <img className="team-members--member_image" src={member.image} />
                <h1 className="team-members--member_name">{member.name}</h1>
                <h2 className="team-members--member_job">{member.job}</h2>
            </div>
        );
    };

    const displayAllMember = members.map(function(member) {
        return(displayMember(member));
    });

    return(
        <div className="container">
            <div className="team">
                <h1 className="team-title">Découvrez notre équipe !</h1>
                <div className="team-members">{displayAllMember}</div>
            </div>
        </div>
    );
};

export default Team;