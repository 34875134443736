import useTitle from '../Assets/useTitle';
import { Link } from 'react-router-dom';

function NotFoundPage() {

    useTitle("404 - Page introuvable")
    return (
        <div className="container" style={{textAlign:"center", height:"100vh"}}>
            <h1 className="404-title" style={{fontSize: "75px"}}>Erreur 404</h1>
            <h2 className="404-subtitle" >Page introuvable</h2>
            <p className="404-button">
              <Link to="/">Retour à l'accueil</Link>
            </p>
        </div>
    );
}

export default NotFoundPage;