import './App.scss';
import { BrowserRouter as Router, Link, Switch, Route } from 'react-router-dom'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Landing from './components/Pages/Landing';
import Presentation from './components/Pages/Presentation';
import Services from './components/Pages/Services';
import Contact from './components/Pages/Contact';
import Team from './components/Pages/Team';
import NotFoundPage from './components/Pages/404';

function App() {
    return (
        <div className="app">
            <Router>
                <Header />
                <Switch>
                    <Route path="/" exact>
                        <Landing />
                    </Route>
                    <Route path="/about" exact>
                        <Presentation />
                    </Route>
                    <Route path="/team" exact>
                        <Team />
                    </Route>
                    <Route path="/services" exact>
                        <Services />
                    </Route>
                    <Route path="/contact" exact>
                        <Contact />
                    </Route>
                    <Route path="/*">
                        <NotFoundPage />
                    </Route>
                </Switch>
                <Footer className="footer"/>
            </Router>
        </div>
    );
}

export default App;