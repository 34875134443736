import useTitle from '../Assets/useTitle';
import ContactForm from '../Assets/ContactForm';
import './scss/Contact.scss';

function Contact() {

    const networks = [
        {
            'title': "Facebook",
            'logo': <i class="fab fa-facebook-square fa-6x"></i>,
            'link': "https://fr-fr.facebook.com/EnsemConseil"
        },
        {
            'title': "LinkedIn",
            'logo': <i class="fab fa-linkedin fa-6x"></i>,
            'link': "https://www.linkedin.com/company/ensem-conseil-je/"
        },
        {
            'title': "Instagram",
            'logo': <i class="fab fa-instagram-square fa-6x"></i>,
            'link': "https://www.instagram.com/ensemconseil"
        },
        {
            'title': "Twitter",
            'logo': <i class="fab fa-twitter-square fa-6x"></i>,
            'link': "https://twitter.com/ensem_conseil"
        }
    ];

    const displayAllNetwork = networks.map(function(network) {
        return(
            <div className="contact-networks--list_network">
                <a href={network.link} target="_blank"><div className="contact-networks--list_network__logo">{network.logo}</div></a>
            </div>
        );
    });

    useTitle("Contact - ENSEM Conseil")
    return (
        <div className="container">
            <div className="contact">
                <ContactForm/>
                <div className="contact-networks mtop">
                    <h1 className="contact-networks--title">Nos réseaux</h1>
                    <div className="contact-networks--list">{displayAllNetwork}</div>
                </div>
                <div className="mentions mtop">
                    <h1 className="mentions-title">Mentions légales</h1>
                    <div className="mentions-content">
                        <p className="mentions-content--text">Conformément aux dispositions de l’article 6 III-1 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, vous trouverez ci-dessous les informations relatives à l’éditeur et à l’hébergeur de ce site internet.</p>
                        <div className="mentions-content--editeur">
                            <h2 className="mentions-content--editeur">Éditeur</h2>
                            <div className="mentions-content--editeur_content">
                                <p className="mentions-content--editeur_content__element">ENSEM Conseil</p>
                                <p className="mentions-content--editeur_content__element">Association loi 1901, affiliée à la CNJE</p>
                                <p className="mentions-content--editeur_content__element">2 Avenue de la Forêt de Haye, 54500 Vandœuvre-Lès-Nancy</p>
                                <p className="mentions-content--editeur_content__element">+33 03.72.74.44.29 | contact@ensemconseil.fr</p>
                                <p className="mentions-content--editeur_content__element">N°SIRET : 34907992100020 | N°URSSAF : 34907992100020</p>
                                <p className="mentions-content--editeur_content__element">N°TVA Intracommunautaire : FR33349079921 | Code APE : 7112B</p>
                            </div>
                        </div>
                        <div className="mentions-content--hebergement">
                            <h2 className="mentions-content--hebergement_title">Hébergeur</h2>
                            <div className="mentions-content--herbergement_content">
                                <p className="mentions-content--herbergement_content__element">1&1 IONOS</p>
                                <p className="mentions-content--herbergement_content__element">7 Pl. de la Gare, 57200 Sarreguemines</p>
                                <p className="mentions-content--herbergement_content__element">www.ionos.fr</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;