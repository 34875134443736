import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Header.scss'
import logo from './../Images/logo.svg';


function Header() {

    const [toggleMenu, setToggleMenu] =  useState(false);
    const [largeur, setLargeur] = useState(window.innerWidth);

    const toggleNavSmallScreen = () => {
        setToggleMenu(!toggleMenu);
    }

    useEffect(() => {

        const changeWidth = () => {
            setLargeur(window.innerWidth);

            if (window.innerWidth > 1000){
                setToggleMenu(false);
            }
        }

        window.addEventListener('resize', changeWidth);
        return () => {
            window.removeEventListener('resize', changeWidth);
        }
    }, [])
    
    return (
        <div className="header">
            <nav>
                <Link to="/"><img className="navbar-logo" src={logo} alt=''/></Link>
                {(toggleMenu || largeur > 1000) && (
                    <ul className="navbar-liste">
                        <li><Link to="/" className="navbar-liste--items" onClick={() => toggleNavSmallScreen()}>ACCUEIL</Link></li>
                        <li><Link to="/about" className="navbar-liste--items" onClick={() => toggleNavSmallScreen()}>PRÉSENTATION</Link></li>
                        <li><Link to="/team" className="navbar-liste--items" onClick={() => toggleNavSmallScreen()}>NOTRE ÉQUIPE</Link></li>
                        <li><Link to="/services" className="navbar-liste--items" onClick={() => toggleNavSmallScreen()}>NOS PRESTATIONS</Link></li>
                        <li><Link to="/contact" className="navbar-liste--items" onClick={() => toggleNavSmallScreen()}>NOUS CONTACTER</Link></li>   
                    </ul>
                )}
                <div onClick={toggleNavSmallScreen} className="navbar-btn"><i className="fas fa-bars"></i></div>
            </nav>
        </div>
    );
}

export default Header;