import './Footer.scss';
import logo from './../Images/logo_blanc.png';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="footer">
            <div className="network-networks">
                <ul className="network-networks--liste">
                    <li className="network-networks--liste_items"><a href="https://fr-fr.facebook.com/EnsemConseil" target="_blank"><i className="fab fa-facebook-square"></i></a></li>
                    <li className="network-networks--liste_items"><a href="https://www.linkedin.com/company/ensem-conseil-je" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                    <li className="network-networks--liste_items"><a href="https://www.instagram.com/ensemconseil" target="_blank"><i className="fab fa-instagram-square"></i></a></li>
                    <li className="network-networks--liste_items"><a href="https://twitter.com/ensem_conseil" target="_blank"><i className="fab fa-twitter-square"></i></a></li>
                </ul>
            </div>
            <div className="footer-content">
                <Link to="/" onClick={() => window.scrollTo(0, 0)}><img className="footer-content--logo" src={logo} alt=''/></Link>
                <ul className="footer-content--liste">
                    <li className="footer-content--liste_items"><Link className="footer-content--liste_items__link" to="/contact" onClick={() => window.scrollTo(0, 10000)}>Mentions légales</Link></li>
                    <li className="footer-content--liste_items">contact@ensemconseil.fr</li>
                    <li className="footer-content--liste_items">Copyright &copy; ENSEM Conseil 2022</li>
                </ul>
            </div>
            <div className="footer-legal">
                Made with <i class="fas fa-heart"></i> and <i class="fas fa-coffee"></i> in Nancy, France
            </div>
        </div>
    );
}

export default Footer;