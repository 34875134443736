import { useState } from 'react';
import { Link } from 'react-router-dom';
import './scss/Landing.scss';
// Importation des images --'
import logo from './../Images/logo_vect.svg';
import efor from './../Images/efor.png';
import alumni from './../Images/alumni.png';
import bnp from './../Images/bnp.png';
import cnje from './../Images/cnje.png';
import emaa from './../Images/emaa.png';
import AnimatedCounter from '../Assets/AnimatedCounter';

function Landing() {

    const [countEffect, setCountEffect] = useState(false);

    const partenaires = [
        {
            'image': efor,
            'link': "https://www.efor-group.fr/"
        },
        {
            'image': emaa,
            'link': "https://emaanancy.fr/"
        },
        {
            'image': alumni,
            'link': "https://ensem.org/"
        },
        {
            'image': cnje,
            'link': "https://junior-entreprises.com/"
        },
        {
            'image': bnp,
            'link': "https://group.bnpparibas/"
        }
    ]

    const displayImage = (partenaire) => {
        return(
            <a href={partenaire.link} className="landing-partenaires--liste_items" target="_blank"><img className="landing-partenaires--liste_items__images" src={partenaire.image} alt=''/></a>
        );
    }
    const displayAllImages = partenaires.map(function(partenaire) {
        return(displayImage(partenaire));
    })

    const items = [
        {
            'icon': <i className="fas fa-users"></i>,
            'number': 400,
            'label': "Élèves qualifiés",
            'delay': 2
        },
        {
            'icon': <i className="fas fa-user"></i>,
            'number': 19,
            'label': "Administrateurs à votre écoute",
            'delay': 53
        },
        {
            'icon': <i className="fas fa-flask"></i>,
            'number': 5,
            'label': "Laboratoires de recherche",
            'delay': 200
        },
        {
            'icon': <i className="fas fa-cogs"></i>,
            'number': 4,
            'label': "Domaines de compétences",
            'delay': 167
        }
    ]
    const displayItem = (item) => {
        return (
            <li className="landing-data--liste_items">
                <div className="landing-data--liste_items__icon">{item.icon}</div>
                <div className="landing-data--liste_items__number"><AnimatedCounter trigger={countEffect} max={item.number} timedelay={item.delay}/></div>
                <div className="landing-data--liste_items__title">{item.label}</div>
            </li>
        );
    }
    const displayAllItems = items.map(function(item) {
        return(displayItem(item));
    })

    window.addEventListener('scroll', () => {
        if (window.scrollY >= 550) {
            setCountEffect(true);
        } else {
            setCountEffect(false);
        }
     });

    return (
        <div className="container">
            <div className="landing">
                <div className="landing-top">
                    <div className="landing-top--content">
                        <h1 className="landing-top--content_title">ENSEM Conseil : la Junior-Entreprise des Mobilités et de l'Énergie.</h1>
                        <h2 className="landing-top--content_subtitle">N'attendez plus pour démarrer votre projet avec nous !</h2>
                        <Link to="/contact"><button className="landing-top--content_btn"><i className="fas fa-angle-right"></i> Démarrer un projet !</button></Link>
                    </div>
                    <img className="landing-top--img" src={logo} alt=''/>
                </div>
                <div className="landing-ad">
                    <h1 className="landing-ad--title">La Junior-Entreprise des Mobilités et de l'Énergie</h1>
                    <h2 className="landing-ad-subtitle">Bénéficiez de notre expertise pour votre projet</h2>
                    <p className="landing-ad--text">Nous sommes une équipe jeune, dynamique et compétente qui saura vous écouter et vous accompagner pour que nous réalisions ensemble votre projet.</p>
                </div>
                <div className="landing-data">
                    <ul className="landing-data--liste">
                        {displayAllItems}
                    </ul>
                </div>
                <div className="landing-partenaires">
                    <h1 className="landing-partenaires--title">Ils nous font confiance</h1>
                    <ul className="landing-partenaires--liste">
                        {displayAllImages}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Landing;