import useTitle from '../Assets/useTitle';
import './scss/Services.scss';
import img_pdf from './../Images/pdf.png';
import pdf_general from './../Files/Plaquettes_Générales_ENSEM_Conseil.pdf';
import pdf_energie from './../Files/Plaquettes_Énergie_ENSEM_Conseil.pdf';
import pdf_mobilite from './../Files/Plaquettes_Mobilités_ENSEM_Conseil.pdf';
import pdf_ges from './../Files/Plaquettes_Optimisation_GES_ENSEM_Conseil.pdf';
import pdf_isn from './../Files/Plaquettes_Sciences_du_Numérique_ENSEM_Conseil.pdf';

function Services() {
    
    useTitle("Services - ENSEM Conseil")

    const domains = [
        {   
            'id': "energie",
            'icon': <i class="fa-solid fa-solar-panel fa-3x"></i>,
            'title': 'Énergie',
            'details': [
                            "Panneaux solaires & éoliennes",
                            "Réseaux électriques",
                            "Motorisations & génération électrique",
                       ],
            'file': pdf_energie
        },
        {
            'id': "mobilite",
            'icon': <i class="fa-solid fa-car fa-3x"></i>,
            'title': 'Mobilité',
            'details': [
                            "Conversion électromécanique",
                            "Combustion laminaire",
                            "Procédés industriels"
                       ],
            'file': pdf_mobilite
        },
        {
            'id': "ges",
            'icon': <i class="fa-solid fa-earth-europe fa-3x"></i>,
            'title': 'Optimisation de bilans GES',
            'details': [
                            "Réseaux de chaleurs",
                            "Étude de cas de couplage"
                       ],
            'file': pdf_ges
        },
        {
            'id': "isn",
            'icon': <i class="fa-solid fa-laptop-code fa-3x"></i>,
            'title': 'Sciences du numérique',
            'details': [
                            "Analyse & conception logiciel",
                            "Développement web",
                            "Sureté de fonctionnement",
                            "Intelligences artificielles"
                       ],
            'file': pdf_isn
        },
    ];

    const flipCard = (domain) => {
        var idName = "#" + domain.id;
        var card = document.querySelector(idName);
        card.classList.toggle('flip');    
    }

    const displayDomain = (domain) => {
        return(
            <div id={domain.id} className="services-domains--liste_items__item flip" onClick={() => flipCard(domain)}>
                <div className="services-domains--liste_items__item___face front">
                    <div className="services-domains--liste_items__icon">{domain.icon}</div>
                    <h1 className="services-domains--liste_items__title">{domain.title}</h1>
                    <div className="services-domains--liste_items__details">
                        {domain.details.map(function(item) {return(<h3 className="services-domains--liste_items__details___item">{item}</h3>);})}
                    </div>
                </div>
                <div className="services-domains--liste_items__item___face back">
                    <div className="services-domains--liste_items__icon">{domain.icon}</div>
                    <h1 className="services-domains--liste_items__title">{domain.title}</h1>
                    <p className="services-domains--liste_items__description"></p>
                    <a href={domain.file} download><img src={img_pdf}/></a>
                    <p className="service-domains--liste_items__subtitle">Téléchargez la plaquette</p>
                </div>
            </div>
        );
    }

    const displayAll = domains.map(function(domain) {
        return(displayDomain(domain));
    })

    return (
        <div className="container">
            <div className="services">
                <div className="services-methodology">
                    <h1 className="services-methodology--title">Notre méthodologie</h1>
                    <div className="services-methodology--timeline">
                        <div className="services-methodology--timeline_barre"></div>
                        <div className="services-methodology--timeline_block left">
                            <i class="fa-solid fa-handshake fa-2x"></i>
                            <h2 className="timeline-title">1. Le rendez-vous client</h2>
                            <p className="timeline-text">Ensemble nous définissons votre besoin et le cadre de votre projet afin d’établir un cahier des charges qui vous correspond au mieux.</p>
                        </div>
                        <div className="services-methodology--timeline_block right">
                            <i class="fa-solid fa-pen fa-2x"></i>
                            <h2 className="timeline-title">2. La proposition commerciale</h2>
                            <p className="timeline-text">ENSEM Conseil vous propose un devis afin de fixer un budget et une méthodologie pour l’étude.</p>
                        </div>
                        <div className="services-methodology--timeline_block left">
                            <i class="fa-solid fa-users fa-2x"></i>
                            <h2 className="timeline-title">3. La sélection de l’intervenant</h2>
                            <p className="timeline-text">Une fois le cadre de l’étude posé, nous choisissons pour vous l’étudiant le plus compétent pour prendre en charge la réalisation de l’étude.</p>
                        </div>
                        <div className="services-methodology--timeline_block right">
                            <i class="fa-solid fa-timeline fa-2x"></i>
                            <h2 className="timeline-title">4. Un suivi continu et personnalisé</h2>
                            <p className="timeline-text">Tout au long de l’étude, nous nous chargerons de maintenir le lien entre le client et l’intervenant afin de satisfaire au mieux vos exigences.</p>
                        </div>
                        <div className="services-methodology--timeline_block left">
                            <i class="fa-solid fa-book-open fa-2x"></i>
                            <h2 className="timeline-title">5. Le livrable final</h2>
                            <p className="timeline-text">Pour conclure le projet nous vous remettons un livrable de qualité puis nous restons à votre disposition pour des questions ou d’éventuels autres projets.</p>    
                        </div>
                    </div>
                </div>
                <div className="services-domains mtop">
                    <h1 className="services-domains--title">Nos prestations</h1>
                    <div className="services-domains--liste_items">
                        {displayAll}
                    </div>
                </div>
                <div className="services-plaquettes mtop mbottom">
                    <h1 className="services-plaquettes--title">Vous souhaitez plus d'informations ?</h1>
                    <a href={pdf_general} download><img className="services-plaquettes--logo" src={img_pdf}/></a>
                    <p className="service-plaquettes--text">Téléchargez la plaquette générale</p>
                </div>
            </div>
        </div>
    );
}

export default Services;