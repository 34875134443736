import React, { useState, useEffect } from 'react';

function AnimatedCounter(props) {

    const [count, setCount] = useState(0);

    useEffect(() => {
        const timer = window.setInterval(() => {
            if (props.trigger) {
                if (count < props.max) {
                    setCount(count + 1);
                }
            }
        }, props.timedelay);
        return () => clearInterval(timer);
    }, [count, props.trigger]);

    return(
        <div>{count}</div>
    );
};

export default AnimatedCounter;