import './scss/Presentation.scss'
import useTitle from '../Assets/useTitle';
import img_mandat from './../Images/team/mandat.jpg';
import img_cnje from './../Images/cnje.png';
import img_ec from './../Images/logo.png';
import img_ensem from './../Images/ensem.jpg';
import img_cran from './../Images/logo_cran.png';
import img_green from './../Images/logo_green.png';
import img_lem3 from './../Images/logo_lem3.png';
import img_loria from './../Images/logo_loria.jpg';
import img_lemta from './../Images/logo_lemta.png';

function Presentation() {
    useTitle("Présentation - ENSEM Conseil")
    return (
        <div className="container">
            <div className="presentation">
                <div className="presentation-block wborder">
                    <h1 className="presentation-block--title">ENSEM Conseil : une Junior-entreprise au sein de la CNJE</h1>
                    <div className="presentation-block--content">
                        <p className="presentation-block--content_texte">Une Junior-Entreprise est une association d'étudiants. Cette structure à but non lucratif dispose d'une vocation à la fois économique et pédagogique : elle ne peut être implantée qu'au sein d'une école ou d'une université. Les entreprises peuvent ainsi faire appel à une Junior-Entreprise pour réaliser des études en lien avec les compétences des étudiants. La Junior-Entreprise bénéficie du statut simplifié de l'association loi 1901 mais sont cependant soumises au contrôle de la Confédération Nationale des Junior-Entreprises (CNJE).</p>
                        <p className="presentation-block--content_texte">ENSEM Conseil est une Junior-Entreprise qui a été fondée en 1977 et qui est implantée au sein de l’ENSEM à Vandœuvre-lès-Nancy.</p>
                        <img id="logo_ec" className="presentation-block--content_image" src={img_ec}/>
                    </div>
                </div>
                <div className="presentation-block wborder ptop">
                    <h1 className="presentation-block--title">L’ENSEM : l’École Nationale Supérieure de la Mécanique et de l’Électricité.</h1>
                    <div className="presentation-block--content">
                        <a className="link-image-ensem" href="https://ensem.univ-lorraine.fr/" target="_blank"><img id="logo_ensem" className="presentation-block--content_image" src={img_ensem}/></a>
                        <p className="presentation-block--content_texte">La Junior-Entreprise Ensem Conseil est implantée au sein de l’ENSEM située à Vandœuvre-lès-Nancy et rattachée à l’Université de Lorraine. Les administrateurs de la Junior-entreprise ainsi que les consultants qui interviennent au sein des missions que nous réalisons sont tous issus de l’ENSEM et suivent donc la formation de haut niveau qui y est enseignée.</p>
                        <p className="presentation-block--content_texte">La formation ingénieur ENSEM se positionne au cœur de la transition énergétique et a pour objectif de former des ingénieurs de haut niveau capables de répondre aux défis sociétaux et économiques inhérents aux transitions énergétiques et numériques dans les secteurs de l’énergie, du transport et de l’industrie au travers de deux cursus : la formation énergie et la formation en systèmes numériques.</p>
                        <p className="presentation-block--content_texte">De plus 5 grands laboratoires se trouvent au sein de notre école et plus de 40 enseignants chercheurs sont à la disposition des élèves intervenants pour les soutenir et leur apporter un accès privilégié à des infrastructures de haut niveau technologiques.</p>
                        <div className="presentation-block--content_labos">
                            <a className="link-image-labo" href="https://lemta.univ-lorraine.fr/" target="_blank"><img className="presentation-block--content_labos__image" src={img_lemta}/></a>
                            <a className="link-image-labo" href="http://green.univ-lorraine.fr/" target="_blank"><img className="presentation-block--content_labos__image" src={img_green}/></a>
                            <a className="link-image-labo" href="http://www.cran.univ-lorraine.fr/" target="_blank"><img className="presentation-block--content_labos__image" src={img_cran}/></a>
                            <a className="link-image-labo" href="http://lem3.univ-lorraine.fr/" target="_blank"><img className="presentation-block--content_labos__image" src={img_lem3}/></a>
                            <a className="link-image-labo" href="https://www.loria.fr/fr/" target="_blank"><img className="presentation-block--content_labos__image" src={img_loria}/></a>
                        </div>
                    </div>
                </div>
                <div className="presentation-block wborder ptop">
                    <h1 className="presentation-block--title">Notre mandat</h1>
                    <div className="presentation-block--content">
                        <p className="presentation-block--content_texte">Notre mandat 2021/2022 est composé du bureau associatif et de 5 pôles : le pôle Activités Commerciales, le pôle Trésorerie, le pôle Communication Externe, le pôle Qualité et enfin le pôle Ressources Humaines. Le bureau, quant à lui, est constitué du Président, du Vice-président, du Secrétaire général, de la Responsable qualité et de la Trésorière.</p>
                        <img className="presentation-block--content_image" src={img_mandat}/>
                        <p className="presentation-block--content_texte">Chaque année le RFP (Recrutement Formation Passation) est au cœur des préoccupations d’ENSEM Conseil. En effet le RFP constitue toute la période de transition entre un mandat N et le mandat N+1. Cet aspect nous tient beaucoup à cœur car il assure la transmission des connaissances et des apprentissages d’un mandat à un autre et permet ainsi de maintenir l’amélioration de la Junior-Entreprise.</p>
                    </div>
                </div>
                <div className="presentation-block ptop">
                    <h1 className="presentation-block--title">La CNJE (garantie qualité)</h1>
                    <div className="presentation-block--content">
                        <a className="link-image-cnje" href="https://junior-entreprises.com/" target="_blank"><img id="logo_cnje" className="presentation-block--content_image" src={img_cnje}/></a>
                        <p className="presentation-block--content_texte">Nous sommes une équipe de jeunes étudiants motivés et déterminés à découvrir le monde de l’entreprise au sein de cette première expérience qu’est pour nous ENSEM Conseil. Nous souhaitons donc par-dessus tout répondre aux besoins de nos clients et leur montrer notre savoir-faire ainsi que notre implication. De plus notre qualité est garantie par le mouvement CNJE qui s’assure chaque année au cours d’un audit que notre structure répond bien aux exigences de la norme Junior-Entreprise. En effet ces audits au-delà d’être des évaluations annuelles nous permettent de constamment améliorer notre fonctionnement, nos processus et nos méthodes et de travailler tout au long de l’année sur notre montée en compétence personnelle.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Presentation;